<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <div class="columns is-vcentered">
        <div class="column">
          <p class="title is-4 has-text-primary" v-if="title">{{ title }}</p>
        </div>
        <div class="column is-narrow" v-if="seller && hasPricingStrategy">
          <button
            class="button is-small is-rounded is-light"
            @click="openPricingStrategy"
          >
            <span class="icon">
              <i class="fal fa-info-circle" />
            </span>
            <span>View pricing strategy</span>
          </button>
        </div>
        <div class="column is-narrow" v-if="showSellerSelect">
          <SellerSelect />
        </div>
      </div>
    </div>
    <div class="column is-12">
      <div class="tabs is-right" :class="{ 'is-fullwidth': $mq === 'mobile' }">
        <ul>
          <li
            v-for="(item, index) in links"
            :key="index"
            :class="{ 'is-active': $route.name === item.name }"
          >
            <router-link :to="{ name: item.name }">
              {{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'StockviewTabs',
  components: {
    SellerSelect: () => import('../components/StockviewSellerSelect')
  },
  watch: {
    seller() {
      this.getPricingStrategy(this.sellerId)
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'sellers']),
    ...mapGetters('stockview/dashboard', ['seller', 'sellerId']),
    ...mapGetters('stockview/listings', ['pricingStrategyItems']),
    showSellerSelect() {
      return this.sellers?.length > 1 || this.isAdmin
    },
    links() {
      return [
        { name: 'stockview', label: 'Dashboard' },
        { name: 'stockview-stock', label: 'My stock' }
      ]
    },
    title() {
      return this.$route.name === 'stockview' ? 'Dashboard' : 'My stock'
    },
    titleIcon() {
      return this.$route.name === 'stockview' ? 'fal fa-home' : 'fal fa-cars'
    },
    hasPricingStrategy() {
      return this.pricingStrategyItems.length
    }
  },
  methods: {
    ...mapActions('stockview/listings', ['getPricingStrategy']),
    openPricingStrategy() {
      this.$modal.open('stockview/PricingStrategy', this.pricingStrategyItems)
      this.$mxp.track('stockview_open_pricing_strategy_modal')
    }
  }
}
</script>
